<template>
  <van-overlay :show="requestShow" @click="requestShow = false">
    <div @click.stop>
      <div class="content">
        <div class="x-close-dialog">
          <van-image width="20px"  @click="requestShow=false" :src="require('@/assets/img/x.png')">
          </van-image>
        </div>
        <div class="content-img">
          <van-image :src="require('@/assets/img/successApply.png')"></van-image>
        </div>
        <div class="text-father">
          <p>您已成功申请【{{}}】服务，审核中......</p>
          <p class="text-p">服务人员会联系您，请保持手机畅通......</p>
        </div>
        <div class="button-father">
          <van-button class="bottom-button" round @click="requestShow = false">
            取消</van-button>
          <van-button round type="info" @click="toServiceOrder">
            查看服务单</van-button>
        </div>
      </div>
    </div>
  </van-overlay>

</template>

<script>
export default {
  name: "request",
  data() {
    return {
      requestShow: true
    }
  },
  methods: {
    toServiceOrder() {
      this.$router.push({path: '/older-service-server'})
    }
  }

}
</script>

<style scoped>
.content {
  height: 10.5rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.content-img {
  width: 3rem;
  margin: 0 auto;
}
.x-close-dialog {
  font-size: 0.6rem;
  text-align: right;
  padding: 0.3rem 0.4rem 0 0
}
.text-father {
  font-size: 0.47rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: left;
  line-height: 0.8rem;
  margin-top: 0.5rem;
  padding: 0 1rem;
}
.text-p {
  color: red;
  font-size: 0.4rem;
}
.button-father {
  margin-top: 0.8rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-around;
}
.bottom-button {
  width: 2.5rem;
  border: 1px #C6C6C6 solid;
}
</style>
